<template>
  <div>
    <div class="container">
        <app-bar
          title="MCU Instansi"
          previousLink="/" />
        <div class="row">
          <div class="px-3 pb-5 pb-md-0 mb-md-3">
            <Loading v-if="loading.mcu" />
            <div v-else-if="mcu">
              <!-- Modal -->
              <div class="modal fade" id="modalKonfirmasi" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
                <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-body">
                      <div class="d-flex flex-column align-items-center justify-content-center text-center">
                        <div class="d-flex flex-column align-items-center justify-content-center">
                          <span class="fw-700 fs-18 lh-28 color-neutral-900 mt-md-3 mb-1">
                            Pernyataan
                          </span>
                        </div>
                      </div>
                      <div class="desc-group mt-3 mt-md-0">
                        <p class="fw-400 fs-14 lh-24 color-neutral-600" style="text-align: justify" v-html="mcu.pernyataan" />
                      </div>
                    </div>
                    <div class="modal-footer d-flex justify-content-between">
                      <button type="button" class="btn btn-danger col py-2" data-bs-dismiss="modal">Tolak</button>
                      <button type="button" class="btn btn-primary col py-2" @click="prepareConfirm">Setuju</button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex pb-3 align-items-center mb-3" style="box-shadow: inset 0px -1px 0px rgba(242, 242, 242, 0.8);">
                <div class="me-3">
                  <img src="@/assets/icon/mcu.png" style="width: 52px; height: 52px;">
                </div>
                <div class="d-flex flex-column">
                  <span class="fs-18 fw-700 color-neutral-900">{{ mcu.biodata.nama_rekanan }}</span>
                  <span class="fw-400 color-neutral-600">ID : {{ mcu.id_mcu }}</span>
                </div>
              </div>

              <div class="d-flex mb-2 align-items-center">
                <i class="ri-user-2-fill fs-34 lh-34 me-3 color-primary-500"></i>
                <div class="d-flex flex-column">
                  <span class="fs-12 fw-400 color-neutral-600">Nama Pasien</span>
                  <span class="fw-700 color-neutral-900 mt-1">{{ mcu.biodata.nama_pasien }}</span>
                </div>
              </div>

              <div class="d-flex mb-2 align-items-center">
                <i class="ri-calendar-event-fill fs-34 lh-34 me-3 color-primary-500"></i>
                <div class="d-flex flex-column">
                  <span class="fs-12 fw-400 color-neutral-600">Tanggal Lahir</span>
                  <span class="fw-700 color-neutral-900 mt-1">{{ mcu.biodata.pas_tgllahir }}</span>
                </div>
              </div>

              <div class="d-flex pb-3 mb-3 align-items-center" style="box-shadow: inset 0px -1px 0px rgba(242, 242, 242, 0.8);">
                <i v-if="mcu.biodata.pas_jenkelamin === 'L'" class="ri-men-fill fs-34 lh-34 me-3 color-primary-500"></i>
                <i v-else class="ri-women-fill fs-34 lh-34 me-3 color-primary-500"></i>
                <div class="d-flex flex-column">
                  <span class="fs-12 fw-400 color-neutral-600">Jenis Kelamin</span>
                  <span class="fw-700 color-neutral-900 mt-1">{{ (mcu.biodata.pas_jenkelamin === 'L') ? 'Laki-laki':'Perempuan' }}</span>
                </div>
              </div>

              <div class="d-flex mb-2 align-items-center">
                <div class="me-3">
                  <img src="@/assets/icon/calendar-yellow.png" style="width: 34px; height: 34px;">
                </div>
                <div class="d-flex flex-column">
                  <span class="fs-12 fw-400 color-neutral-600">Tanggal Perkiraan Datang</span>
                  <span class="fw-700 color-neutral-900 mt-1">{{ mcu.biodata.tgl_perkiraan_datang }}</span>
                </div>
              </div>

              <div class="d-flex mb-4 align-items-center">
                <div class="me-3">
                  <img src="@/assets/icon/calendar-green.png" style="width: 34px; height: 34px;">
                </div>
                <div class="d-flex flex-column">
                  <span class="fs-12 fw-400 color-neutral-600">Tanggal Kedatangan</span>
                  <span class="fw-700 color-neutral-900 mt-1">{{ mcu.tgl_datang ?? 'Belum Datang' }}</span>
                </div>
              </div>

              <button class="btn btn-primary btn-login w-100" data-bs-toggle="modal" data-bs-target="#modalKonfirmasi">Detail MCU</button>
            </div>
            <div v-else>
                <span class="fw-700 fs-18">Kode Perusahaan</span>
                <p class="fw-400 color-neutral-600 mb-0">Masukkan kode perusahaan anda untuk <br> mengetahui layanan MCU Instansi yang aktif</p>

                <input type="text" class="form-control form-capsule mt-4" :class="{'has-input': kodeRekanan}" placeholder="Kode Perusahaan" v-model="kodeRekanan">
                <button class="btn btn-primary btn-login w-100 mt-3" :disabled="!kodeRekanan" @click="prepareMcu">Cari</button>

                <div class="w-100 my-3 px-0" v-if="!mcu && pernahCari">
                  <div class="card-info p-3 d-flex flex-row align-items-center bg-danger-50 border-0 color-danger-500">
                    <i class="ri-information-line fs-24"></i>
                    <p class="mb-0 px-2 fs-12 fw-400">Pastikan anda sudah mengisi data nomor KTP dengan benar.</p>
                  </div>
                </div>

                <Empty class="mt-5" v-if="!mcu && pernahCari"
                  :title="'Kode perusahaan tidak ditemukan'"
                  :message="'Maaf kode yang anda masukkan tidak terdaftar dilayanan MCU Instansi'"/>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import AppBar from '@/components/AppBar.vue'
import Empty from '@/components/utility/Empty'
import Loading from '@/components/utility/Loading'
import { useRouter, useRoute } from 'vue-router'
import { title } from '@/core/page'
import { showNotif } from '@/core/utility'
import { getMcu, mcu, loading, confirmMcu, kodeRekanan, getDetailMcu } from '@/core/mcu'
import { user } from '@/core/auth'
import { nextTick, onMounted, ref, onBeforeUnmount } from 'vue'

export default {
  components: {
    Empty, Loading, AppBar
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const pernahCari = ref(false)

    const prepareMcu = async () => {
      try {
        pernahCari.value = true
        const result = await getMcu({
          kode_rekanan: kodeRekanan.value,
          pas_noktp: user.value.pas_noktp
        })

        if (!result.status) {
          showNotif({
            status: 'error',
            message: result.message
          })
        }
      } catch (error) {
        showNotif({
          status: 'error',
          message: error.response.data.message
        })
      }
    }

    const prepareConfirm = async () => {
      try {
        const modal = Modal.getOrCreateInstance(document.querySelector('#modalKonfirmasi'))
        modal.toggle()
        await confirmMcu({
          id: user.value.id,
          id_mcu: mcu.value.id_mcu
        })
        await getDetailMcu({
          id: user.value.id,
          id_mcu: mcu.value.id_mcu
        })
        router.push({ name: 'MCUDetail', params: { kode: mcu.value.id_mcu } })
      } catch (error) {
        showNotif({
          status: 'error',
          message: error.response?.data?.message ?? 'Gagal konfirmasi kehadiran'
        })
      }
    }

    onMounted(() => {
      nextTick(() => {
        title.value = 'MCU Instansi'

        // uncomment untuk debugging
        // kodeRekanan.value = '110408'
      })
    })

    onBeforeUnmount(() => {
      if (route.name === 'Home') {
        mcu.value = null
      }
    })

    return {
      kodeRekanan,
      mcu,
      prepareMcu,
      pernahCari,
      loading,
      prepareConfirm
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-login {
  border: none;
  padding: 10px 20px;
}

.has-input {
  border: 1px solid #4A5568;
}

.card-info {
  border-radius: 16px;

  .card-footer {
    border-radius: 16px;
    border: none;
  }
}

.modal-footer {
  border-top: none;
}

.btn-tolak {
  background-color: #CBD5E0;
}
</style>
